import React, {useEffect, useState} from 'react'
import InfoStep from './info-step/InfoStep'
import LanguagesStep from './language-step/LanguagesStep'
import ShowHide from '@/components/ui/ShowHide'
import {CompletedVideoForm, VideoForm, VideoInfoForm, VideoLanguagesForm} from '@/features/videos/types/video'
import {useCreateUrlVideo} from '../../queries/useCreateUrlVideo'
import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {VideoCreationRequest, VideoDetails} from '@/types/video'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {ROUTE_VIDEO_INGESTION, USER_SIGNUP_SOURCE} from '@/utilities/constants'
import {AxiosError} from 'axios'
import {VIDEO_MODEL} from '../../services/VideoCreationFormModel'
import {useCreateEuropeanaVideo} from '../../queries/useCreateEuropeanaVideo'
import useAuthStore from '@/store/authStore'
import {Language} from '@/types/commons'
import {dropboxUrlRegex, googleUrlMatchRegex, googleUrlRegex} from '../../utilities/constants'

type FieldError = {
    rule: string
    field: string
    message: string
}

type ErrorData = {
    errors?: FieldError[]
}

type StepsErrors = {
    [Steps.InfoStep]: AxiosError<ErrorData> | null
    [Steps.LanguageStep]: AxiosError<ErrorData> | null
}

enum Steps {
    InfoStep = 'info-step',
    LanguageStep = 'language-step'
}

export const getStepErrors = (error: AxiosError<ErrorData>) => {
    const result: StepsErrors = {
        [Steps.InfoStep]: null,
        [Steps.LanguageStep]: null
    }
    const fieldErrors = error?.response?.data?.errors?.map(item => item.field)
    if ([VIDEO_MODEL.Event, VIDEO_MODEL.Name, VIDEO_MODEL.Url].find(item => fieldErrors?.includes(item))) {
        result[Steps.InfoStep] = error
    }
    if ([VIDEO_MODEL.SourceLanguageId, VIDEO_MODEL.TargetLanguagesIds].find(item => fieldErrors?.includes(item))) {
        result[Steps.LanguageStep] = error
    }
    return result
}

const parseUrl = (url: string): string => {
    if (googleUrlRegex.test(url)) {
        const match = url.match(googleUrlMatchRegex)
        return match ? `https://drive.usercontent.google.com/u/0/uc?id=${match[1]}&export=download` : ''
    } else if (dropboxUrlRegex.test(url)) {
        return `${url.replace('&dl=0', '').replace('&dl=1', '')}&dl=1`
    } else {
        return url
    }
}

const adaptVideoToBeSent = (videoForm: CompletedVideoForm): VideoCreationRequest => ({
    eventId: parseInt(`${videoForm.event?.value}`),
    name: videoForm?.name,
    url: parseUrl(videoForm?.url),
    sourceLanguageId: parseInt(`${videoForm.sourceLanguageId.value}`),
    targetLanguagesIds: videoForm.targetLanguagesIds.map(item => parseInt(`${item.value}`)),
    thumbnailUrl: videoForm?.thumbnailUrl ? videoForm?.thumbnailUrl : undefined,
    europeanaRightsUrl: videoForm?.europeanaRightsUrl ? videoForm?.europeanaRightsUrl : undefined,
    europeanaProjectId: videoForm?.europeanaProjectId ? videoForm?.europeanaProjectId : undefined,
    europeanaPlatformSourceUrl: videoForm?.europeanaPlatformSourceUrl
        ? videoForm?.europeanaPlatformSourceUrl
        : undefined,
    description: videoForm?.description
})

const VideoCreationForm: React.FC = () => {
    const {workspaceId} = useParams()
    const navigate = useNavigate()
    const user = useAuthStore(store => store.user)
    const [currentStep, setCurrentStep] = useState(0)
    const [videoData, setVideoData] = useState<VideoForm>({})
    const [isEuropeanaSource, setIsEuropeanaSource] = useState(false)
    const [europeanaLanguage, setEuropeanaLanguage] = useState<Language | null>(null)

    const {
        mutate: createUrlVideoMutate,
        isError: createUrlVideoIsError,
        error: createUrlVideoError,
        isPending: createUrlVideoIsPending
    } = useCreateUrlVideo({
        workspaceId: `${workspaceId}`,
        onSuccess: (data: VideoDetails) => {
            navigate(generatePath(ROUTE_VIDEO_INGESTION, {workspaceId: `${workspaceId}`, videoId: `${data.id}`}))
        }
    })

    const {
        mutate: createEuropeanaVideoMutate,
        isError: createEuropeanaIsError,
        error: createEuropeanaVideoError,
        isPending: createEuropeanaIsPending
    } = useCreateEuropeanaVideo({
        workspaceId: `${workspaceId}`,
        onSuccess: (data: VideoDetails) => {
            navigate(generatePath(ROUTE_VIDEO_INGESTION, {workspaceId: `${workspaceId}`, videoId: `${data.id}`}))
        }
    })

    const isError = createUrlVideoIsError || createEuropeanaIsError
    const error = createUrlVideoError || createEuropeanaVideoError
    const isPending = createUrlVideoIsPending || createEuropeanaIsPending

    const isEuropeanaUser = user?.signupSource === USER_SIGNUP_SOURCE.europeana
    const [stepsErrors, setStepErrors] = useState<StepsErrors>({
        [Steps.InfoStep]: null,
        [Steps.LanguageStep]: null
    })

    useEffect(() => {
        const errors = getStepErrors(error as AxiosError<ErrorData>)
        setStepErrors(errors)
        if (!errors[Steps.InfoStep] && !errors[Steps.LanguageStep]) {
            handleApiError({error: error as ValidationError<string>})
        }
        if (errors[Steps.InfoStep]) {
            setCurrentStep(0)
        }
    }, [isError])

    const onClickPreviousStep = () => {
        setCurrentStep(value => value - 1)
    }

    const onSubmitInfoStep = (values: VideoInfoForm) => {
        setVideoData(state => ({...state, ...values}))
        setCurrentStep(value => value + 1)
    }

    const onSubmitLanguagesStep = (values: VideoLanguagesForm) => {
        const dataToSend = {...videoData, ...values}
        setVideoData(dataToSend)
        if (isEuropeanaSource) {
            createEuropeanaVideoMutate(adaptVideoToBeSent(dataToSend as CompletedVideoForm))
        } else {
            createUrlVideoMutate(adaptVideoToBeSent(dataToSend as CompletedVideoForm))
        }
    }

    return (
        <>
            <ShowHide isVisible={currentStep === 0}>
                <InfoStep
                    onSubmit={onSubmitInfoStep}
                    error={stepsErrors[Steps.InfoStep]}
                    isError={stepsErrors[Steps.InfoStep] !== null}
                    isEuropeanaUser={isEuropeanaUser}
                    setIsEuropeanaSource={setIsEuropeanaSource}
                    setEuropeanaLanguage={setEuropeanaLanguage}
                />
            </ShowHide>
            <ShowHide isVisible={currentStep === 1}>
                <LanguagesStep
                    europeanaLanguage={europeanaLanguage}
                    onClickPreviousStep={onClickPreviousStep}
                    onSubmit={onSubmitLanguagesStep}
                    isLoading={isPending}
                    error={stepsErrors[Steps.LanguageStep]}
                    isError={stepsErrors[Steps.LanguageStep] !== null}
                />
            </ShowHide>
        </>
    )
}

export default VideoCreationForm

VideoCreationForm.displayName = 'VideoCreationForm'
